import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { CVMakerPage } from '../pages/CVMakerPage'
import { AboutPage } from '../pages/AboutPage'
import '../styles/ContentBox.css'

interface ContectBoxProps {
  children: React.ReactNode
}

export function ContectBox({children}: ContectBoxProps) {
  return (
    <>
      <div className='content-background'>
        {children}
      </ div>
    </>
  )
}