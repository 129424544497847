import React from 'react'
import logo from '../logo.svg'
import { Link } from 'react-router-dom'
import '../App.css'

interface MenuPageProps {
	onSelect: () => void
}

export function MenuPage({onSelect} : MenuPageProps) {

	return (
		<>
			<div className="App">
				<div className="App-header">
					<img src={logo} className="App-logo" alt="logo" />
					<nav className=''>
						<span>
							<Link to='/' className='Nav-Button' onClick={onSelect}>Products</Link>
							<Link to='/about' className='Nav-Button' onClick={onSelect}>About</Link>
						</span>
					</nav>
				</div>
			</div>
		</>
	)
}