import React, { useRef } from 'react'
import { MenuPage } from './pages/MenuPage'
import { ContectBox } from './components/ContectBox'
import { Routes, Route } from 'react-router-dom'
import { CVMakerPage } from './pages/CVMakerPage'
import { AboutPage } from './pages/AboutPage'

function App() {
  const contentStartRef = useRef<null | HTMLDivElement>(null)

  const scrollToContent = () => {
    contentStartRef.current?.scrollIntoView({behavior: 'smooth'})
  }

  return (
    <>
      <MenuPage onSelect={scrollToContent} />
      <div ref={contentStartRef}/>
      <ContectBox>
        <Routes >
          <Route path='/' element={<CVMakerPage />} />
          <Route path='/about' element={<AboutPage />} />
        </Routes>
      </ContectBox>
    </>
  )
}

export default App;
